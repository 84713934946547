<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">
      {{
        $router.currentRoute.value.name == "UpdatePodcaster" ? "Update" : "Add"
      }}
      Podcaster
    </h2>
    <form @submit.prevent="submitForm">
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Podcaster Information</div>
        </template>
        <template #content>
          <div class="grid">
            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="name">Name </label>
              <InputText
                id="name"
                v-model="form.name"
                :class="{ 'p-invalid': submitted && !form.name }"
              ></InputText>
              <small v-if="submitted && !form.name" class="p-error"
                >Name is required</small
              >
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="email">Email </label>
              <InputText
                id="email"
                type="email"
                v-model="form.email"
                :class="{
                  'p-invalid': submitted && (!form.email || !emailValid),
                }"
              ></InputText>
              <small v-if="submitted && !emailValid" class="p-error"
                >Invalid email format</small
              >
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="password">Password </label>
              <InputText
                id="password"
                type="password"
                autocomplete="new-password"
                placeholder="Enter password"
                v-model="form.password"
                :class="{
                  'p-invalid': submitted && (!form.password || !passwordValid),
                }"
              ></InputText>
              <small
                v-if="submitted && !form.podcasterId && !passwordValid"
                class="p-error"
                >Password must be at least 8 chars, with upper-case and
                lower-case letters</small
              >
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="status">Status</label>
              <Dropdown
                id="status"
                v-model="form.status"
                :options="statusOptions"
                optionLabel="label"
                optionValue="value"
                placeholder="Choose Status"
              />
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="type">Type</label>
              <Dropdown
                id="podcaster_type"
                v-model="form.podcaster_type"
                :options="typeOptions"
                optionLabel="label"
                optionValue="value"
                placeholder="Choose Type"
              />
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="plan">Plan</label>
              <Dropdown
                id="plan"
                v-model="form.plan"
                :options="planOptions"
                optionLabel="label"
                optionValue="value"
                placeholder="Choose Type"
              />
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="revshare">Revenue Share</label>
              <InputNumber
                id="revshare"
                v-model="form.revshare"
                :options="planOptions"
                optionLabel="label"
                optionValue="value"
                placeholder=""
                :minFractionDigits="2"
              />
            </div>
            <!-- <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="avg_per_episode">Avg per Episode </label>
              <InputNumber
                id="avg_per_episode"
                placeholder="Avg per Episode"
                v-model="form.avg_per_episode"
              ></InputNumber>
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="avg_per_month">Avg per Month </label>
              <InputNumber
                id="avg_per_month"
                placeholder="Avg per Month"
                v-model="form.avg_per_month"
              ></InputNumber>
            </div> -->
          </div>
        </template>
      </Card>
      <div class="w-full flex justify-content-end gap-2">
        <Button
          class="flex-1 sm:flex-initial"
          outlined
          label="Cancel"
          @click="router.go(-1)"
        />
        <Button class="flex-1 sm:flex-initial" type="submit" label="Submit" />
      </div>
    </form>
  </div>
</template>

<script setup>
import Card from "primevue/card";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import { ref } from "vue";
import axios from "axios";
import { API_BASE_URL } from "@/consts";
import { onMounted } from "vue";
import { computed } from "vue";
import { emailValidator, passwordValidator } from "@/helpers";
import InputNumber from "primevue/inputnumber";

const router = useRouter();
const store = useStore();
const toast = useToast();

const submitted = ref(false);

const statusOptions = [
  { label: "Pending", value: "pending" },
  { label: "Form Submitted", value: "ob_form_submitted" },
  { label: "Active", value: "active" },
];
const typeOptions = [
  { label: "Individual", value: "individual" },
  { label: "Network", value: "network" },
  { label: "Publisher", value: "publisher" },
];
const planOptions = [
  { label: "Trial", value: "trial" },
  { label: "Jamx Pro", value: "jamx pro" },
  { label: "Jamx Pro +", value: "jamx pro plus" },
  { label: "Jamx Network", value: "jamx network" },
];

const form = ref({
  name: "",
  email: "",
  password: "",
  // avg_per_episode: 0,
  // avg_per_month: 0,
  status: statusOptions[0].value,
  podcaster_type: typeOptions[0].value,
  plan: planOptions[0].value,
});

const podcasterId = router.currentRoute.value.params.podcasterId;
const token = localStorage.getItem("podcastAdminToken");

const emailValid = computed(() => emailValidator(form.value.email));
const passwordValid = computed(() => passwordValidator(form.value.password));

const getPodcasterData = async () => {
  try {
    store.commit("setLoading", true);
    const response = await axios.get(
      API_BASE_URL + "/podcaster/detail/" + podcasterId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data.success) {
      const {
        name,
        email,
        status,
        podcaster_type,
        plan,
        avg_per_episode,
        avg_per_month,
        revshare,
      } = response.data.podcaster;
      form.value = {
        ...form.value,
        name,
        email,
        // avg_per_episode,
        // avg_per_month,
        status: status?.length ? status : statusOptions[0].value,
        podcaster_type: podcaster_type?.length
          ? podcaster_type
          : typeOptions[0].value,
        plan: plan?.length ? plan : typeOptions[0].value,
        revshare,
      };
    } else
      toast.add({
        severity: "error",
        summary: "Podcaster data not found",
        life: 4000,
      });
  } catch (error) {
    console.error(error);
    toast.add({
      severity: "error",
      summary: "Something went wrong...",
      life: 4000,
    });
  }
  store.commit("setLoading", false);
};

const submitForm = async () => {
  submitted.value = true;
  if (
    form.value.name &&
    form.value.email &&
    emailValid.value &&
    (form.value.podcasterId || passwordValid.value)
  ) {
    try {
      store.commit("setLoading", true);

      const response = await axios.post(
        API_BASE_URL + "/add-podcaster",
        form.value,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data?.success) {
        // Clear form after successful submission
        form.value = {
          name: "",
          email: "",
          password: "",
          // avg_per_episode: 0,
          // avg_per_month: 0,
          status: statusOptions[0].value,
          podcaster_type: typeOptions[0].value,
          plan: planOptions[0].value,
        };

        submitted.value = false;
        store.dispatch("loadPodcasters", { token });
        toast.add({
          severity: "success",
          summary: "Success",
          detail: `Podcaster ${
            podcasterId ? "updated" : "added"
          } successfully!`,
          life: 4000,
        });
        router.replace({ name: "PodcastersList" });
      } else {
        toast.add({
          severity: "error",
          detail: `Something went wrong...`,
          life: 4000,
        });
      }
    } catch (error) {
      toast.add({
        severity: "error",
        detail: `Something went wrong...`,
        life: 4000,
      });
      console.log(error);
    } finally {
      store.commit("setLoading", false);
    }
  }
};

onMounted(() => {
  if (podcasterId) {
    form.value.podcasterId = podcasterId;
    getPodcasterData();
  }
});
</script>
