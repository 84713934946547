<template>
  <div class="grid">
    <div v-for="cardItem in cardsData" class="col-12 sm:col-6 lg:col-3">
      <StatCard
        :title="cardItem.title"
        :contentStr="cardItem.contentStr"
        :cardType="cardItem.cardType"
        :subContentStr="cardItem.subContentStr"
        :loader="cardItem.loader"
        :isPlus="cardItem.isPlus"
      />
    </div>
  </div>
</template>

<script setup>
import { formatMoney } from "@/helpers";
import { onBeforeMount } from "vue";
import { useStore } from "vuex";
import StatCard from "./StatCard.vue";
import { computed } from "vue";

const store = useStore();

const percentageDiff = (itemName) => {
  const valueToday =
    store.state.AdminNewDashboardStore.dailyPace.today[itemName];
  const valueYesterday =
    store.state.AdminNewDashboardStore.dailyPace.yesterday[itemName];
  return (((valueToday - valueYesterday) / valueYesterday) * 100).toFixed(2);
};

const cardsData = computed(() => [
  {
    title: "Revenue Today",
    contentStr: formatMoney(
      store.state.AdminNewDashboardStore.dailyPace.today.revenue
    ),
    cardType: "diff",
    subContentStr: `${percentageDiff("revenue")}%`,
    loader: store.state.AdminNewDashboardStore.loader["dailyPace"],
    isPlus: percentageDiff("revenue") >= 0,
  },
  {
    title: "Revenue This Month",
    contentStr: formatMoney(
      store.state.AdminNewDashboardStore.mrr.revenueThisMonth
    ),
    cardType: "minimal",
    subContentStr: "",
    loader: store.state.AdminNewDashboardStore.loader["mrr"],
  },
  {
    title: "Impressions Today",
    contentStr: formatMoney(
      store.state.AdminNewDashboardStore.dailyPace.today.impressions,
      "decimal"
    ),
    cardType: "diff",
    subContentStr: `${percentageDiff("impressions")}%`,
    loader: store.state.AdminNewDashboardStore.loader["dailyPace"],
    isPlus: percentageDiff("impressions") >= 0,
  },
  {
    title: "Requests Today",
    contentStr: formatMoney(
      store.state.AdminNewDashboardStore.dailyPace.today.requests,
      "decimal"
    ),
    cardType: "diff",
    subContentStr: `${percentageDiff("requests")}%`,
    loader: store.state.AdminNewDashboardStore.loader["dailyPace"],
    isPlus: percentageDiff("requests") >= 0,
  },
  {
    title: "Loads Today",
    contentStr: formatMoney(
      store.state.AdminNewDashboardStore.dailyPace.today.loads,
      "decimal"
    ),
    cardType: "diff",
    subContentStr: `${percentageDiff("loads")}%`,
    loader: store.state.AdminNewDashboardStore.loader["dailyPace"],
    isPlus: percentageDiff("loads") >= 0,
  },
  {
    title: "AVG Ad PlayRate",
    contentStr: formatMoney(
      store.state.AdminNewDashboardStore.dailyPace.today.fill_rate,
      "percent"
    ),
    cardType: "diff",
    subContentStr: `${percentageDiff("fill_rate")}%`,
    loader: store.state.AdminNewDashboardStore.loader["dailyPace"],
    isPlus: percentageDiff("fill_rate") >= 0,
  },
  {
    title: "Yearly Run Rate",
    contentStr: formatMoney(store.state.AdminNewDashboardStore.mrr.yrr),
    cardType: "normal",
    subContentStr: `Average Daily: ${formatMoney(
      store.state.AdminNewDashboardStore.mrr.averageDailyRevenue
    )}`,
    loader: store.state.AdminNewDashboardStore.loader["mrr"],
  },
  {
    title: "Monthly Run Rate",
    contentStr: formatMoney(store.state.AdminNewDashboardStore.mrr.mrr),
    cardType: "normal",
    subContentStr: `Average Daily: ${formatMoney(
      store.state.AdminNewDashboardStore.mrr.averageDailyRevenue
    )}`,
    loader: store.state.AdminNewDashboardStore.loader["mrr"],
  },
]);

onBeforeMount(() => {
  store.dispatch("AdminNewDashboardStore/getDailyPace");
  store.dispatch("AdminNewDashboardStore/getMRR");
});
</script>
