<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">
      {{ tagId ? "Update" : "Add" }} Tag
    </h2>
    <form @submit.prevent="submitTag">
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Tag Details</div>
        </template>
        <template #content>
          <div class="grid">
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="name">Name</label>
              <InputText
                id="name"
                v-model="form.name"
                :class="{ 'p-invalid': submitted && !form.name }"
              />
              <small v-if="submitted && !form.name" class="p-error"
                >Name is required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="cpm">CPM</label>
              <InputNumber
                id="cpm"
                v-model="form.cpm"
                :class="{ 'p-invalid': submitted && !form.cpm }"
              />
              <small v-if="submitted && !form.cpm" class="p-error"
                >CPM is required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="url">URL</label>
              <InputText
                id="url"
                v-model="form.url"
                :class="{ 'p-invalid': submitted && !form.url }"
              />
              <small v-if="submitted && !form.url" class="p-error"
                >URL is required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="tag_group">Tag Group</label>
              <Dropdown
                inputId="tag_group"
                v-model="form.tag_group"
                :options="tagGroups"
                optionLabel="name"
                optionValue="id"
                placeholder="Select a Tag Group"
                class="w-full"
              />
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="tier">Tier</label>
              <Dropdown
                inputId="tier"
                v-model="form.tier"
                :options="tierOptions"
                placeholder="Select Tier"
                class="w-full"
              />
            </div>
          </div>
        </template>
      </Card>
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Restrictions</div>
        </template>
        <template #content>
          <div class="grid">
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="geo_bl">Geolocation Blacklist</label>
              <MultiSelect
                inputId="geo_bl"
                v-model="form.geos_blacklist"
                :options="countryList"
                optionLabel="name.common"
                optionValue="cca2"
                placeholder="Select Countries"
                display="chip"
                filter
                class="w-full"
              />
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="geo_wl">Geolocation Whitelist</label>
              <MultiSelect
                inputId="geo_wl"
                v-model="form.geos_whitelist"
                :options="countryList"
                optionLabel="name.common"
                optionValue="cca2"
                placeholder="Select Countries"
                display="chip"
                filter
                class="w-full"
              />
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="sel_bl">Seller Blacklist</label>
              <TreeSelect
                inputId="sel_bl"
                v-model="sellersBlacklist"
                :options="partnersSellersObj"
                selectionMode="checkbox"
                placeholder="Select Item"
                class="w-full"
                @change="handleSelBlChange"
                @hide="partnersSellersObjKeyword = ''"
              >
                <template #header>
                  <div style="padding: 20px 20px 0">
                    <InputText
                      class="w-full"
                      placeholder="Type to search"
                      type="text"
                      v-model="partnersSellersObjKeyword"
                    />
                  </div>
                </template>
              </TreeSelect>
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="sel_wl">Seller Whitelist</label>
              <TreeSelect
                inputId="sel_wl"
                v-model="sellersWhitelist"
                :options="partnersSellersObj"
                selectionMode="checkbox"
                placeholder="Select Item"
                class="w-full"
                @change="handleSelWlChange"
                @hide="partnersSellersObjKeyword = ''"
              >
                <template #header>
                  <div style="padding: 20px 20px 0">
                    <InputText
                      class="w-full"
                      placeholder="Type to search"
                      type="text"
                      v-model="partnersSellersObjKeyword"
                    />
                  </div>
                </template>
              </TreeSelect>
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6 p-fluid">
              <label for="domains_bl">Domain Blacklist</label>
              <Chips
                input-id="domains_bl"
                v-model="form.domains_bl"
                separator=","
                placeholder="Enter domains"
              />
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6 p-fluid">
              <label for="domains_wl">Domain Whitelist</label>
              <Chips
                input-id="domains_wl"
                v-model="form.domains_wl"
                separator=","
                placeholder="Enter domains"
              />
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6 p-fluid">
              <label for="os_bl">OS Blacklist</label>
              <Chips
                input-id="os_bl"
                v-model="form.os_bl"
                separator=","
                placeholder="Enter OS"
              />
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6 p-fluid">
              <label for="os_wl">OS Whitelist</label>
              <Chips
                input-id="os_wl"
                v-model="form.os_wl"
                separator=","
                placeholder="Enter OS"
              />
            </div>
          </div>
        </template>
      </Card>
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Others</div>
        </template>
        <template #content>
          <div class="grid">
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="content_pack">Content Pack</label>
              <Dropdown
                inputId="content_pack"
                v-model="form.content_pack_id"
                :options="[{ id: null, name: 'None' }, ...contentPacks]"
                optionLabel="name"
                optionValue="id"
                placeholder="Select Content Pack"
                class="w-full"
              />
            </div>

            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="wc_percent">WC Percent</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">%</span>
                <InputNumber
                  inputId="wc_percent"
                  v-model="form.wc_percent"
                  :min="0"
                  :max="100"
                />
              </div>
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="priority">Priority</label>
              <InputNumber
                inputId="priority"
                v-model="form.priority"
                :min="0"
                :max="100"
              />
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="active">Active Status</label>
              <Dropdown
                inputId="active"
                v-model="form.active"
                :options="activeStatus"
                optionLabel="label"
                optionValue="value"
                placeholder="Select Status"
                class="w-full"
              />
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="tag_type">Tag Type</label>
              <Dropdown
                inputId="tag_type"
                v-model="form.tag_type"
                :options="tagTypes"
                optionLabel="label"
                optionValue="value"
                placeholder="Select Tag Type"
                class="w-full"
              />
            </div>
          </div>
        </template>
      </Card>
      <div class="w-full flex justify-content-end gap-2">
        <Button
          class="flex-1 sm:flex-initial"
          outlined
          label="Cancel"
          @click="router.go(-1)"
        />
        <Button class="flex-1 sm:flex-initial" type="submit" label="Submit" />
      </div>
    </form>
  </div>
</template>

<script setup>
import axios from "axios";
import { API_BASE_URL } from "../consts";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import { useRouter } from "vue-router";

import Button from "primevue/button";
import Card from "primevue/card";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import MultiSelect from "primevue/multiselect";
import TreeSelect from "primevue/treeselect";
import Dropdown from "primevue/dropdown";
import { onMounted, ref, watch } from "vue";
import { sortByField } from "../helpers";
import Chips from "primevue/chips";

const store = useStore();
const toast = useToast();
const router = useRouter();

const submitted = ref(false);

const tagTypes = [
  { label: "Vast", value: "vast" },
  { label: "Ima", value: "ima" },
];

const activeStatus = [
  { label: "Inactive", value: false },
  { label: "Active", value: true },
];

const tierOptions = ["3", "2", "1"];

const form = ref({
  name: "",
  url: "",
  cpm: 0,
  geos_blacklist: [],
  geos_whitelist: [],
  wc_percent: 0,
  tag_group: 0,
  active: activeStatus[0].value,
  partner: "",
  priority: 0,
  sel_bl: [],
  sel_wl: [],
  tag_type: tagTypes[0].value,
  domains_wl: [],
  domains_bl: [],
  os_wl: [],
  os_bl: [],
  content_pack_id: null,
  tier: "3",
});
const contentPacks = ref([]);

const partners = ref([]);
const tagGroups = ref([]);
const countryList = ref([]);
const partnersSellersObj = ref([]);
const partnersSellersObjKeyword = ref("");
const sellersBlacklist = ref({});
const sellersWhitelist = ref({});
const tagId = router.currentRoute.value.params.tagId;

if (tagId) {
  form.value.tagId = tagId;
}

const fetchContentPacks = async () => {
  try {
    const token = localStorage.getItem("adminToken");
    const response = await axios.get(API_BASE_URL + "/content-packs", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    contentPacks.value = response.data;
  } catch (error) {
    console.log(error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to fetch content packs",
      life: 3000,
    });
  }
};

const handleSelBlChange = (value) => {
  let tempList = {};
  for (const key in value) {
    if (Object.hasOwnProperty.call(value, key)) {
      const item = value[key];
      if (key.includes("_") && tempList[key.split("_")[0]]?.checked) continue;
      tempList[key] = item;
    }
  }
  sellersBlacklist.value = tempList;
};

const handleSelWlChange = (value) => {
  let tempList = {};
  for (const key in value) {
    if (Object.hasOwnProperty.call(value, key)) {
      const item = value[key];
      if (key.includes("_") && tempList[key.split("_")[0]]?.checked) continue;
      tempList[key] = item;
    }
  }
  sellersWhitelist.value = tempList;
};

function filterData(data, searchKeyword) {
  for (const item of data) {
    if (
      item.label.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      item.children?.some((child) =>
        child.label.toLowerCase().includes(searchKeyword.toLowerCase())
      )
    ) {
      item.styleClass = undefined;
      if (item.children && item.children.length > 0) {
        filterData(item.children, searchKeyword);
      }
    } else {
      item.styleClass = "hideparent";
    }
  }
}

watch(partnersSellersObjKeyword, (newKeyword) => {
  filterData(partnersSellersObj.value, newKeyword);
});

const fetchCountryList = async () => {
  try {
    store.commit("setLoading", true);

    const response = await axios.get(
      "https://restcountries.com/v3.1/all?fields=name,cca2"
    );
    if (response.data) {
      countryList.value = response.data;
      sortByField(countryList.value, "name", "common");
    }
  } catch (error) {
    console.error("Error fetching countries:", error);
  } finally {
    store.commit("setLoading", false);
  }
};

const fetchPartners = async () => {
  try {
    store.commit("setLoading", true);

    const token = localStorage.getItem("adminToken");
    const response = await axios.get(API_BASE_URL + "/partners", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data) {
      partners.value = [...response.data];
      sortByField(partners.value, "name");
      fetchPartnerSellers();
    }
  } catch (error) {
    console.error("Error fetching partners:", error);
  } finally {
    store.commit("setLoading", false);
  }
};

const fetchPartnerSellers = async () => {
  try {
    store.commit("setLoading", true);

    const token = localStorage.getItem("adminToken");
    const response = await axios.get(API_BASE_URL + "/sellers/by/partner", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.success) {
      let sellersTemp = response.data.sellers;
      partners.value.map((item) => {
        let firstLevelChild = {
          key: item.id,
          label: item.name,
          children: [],
        };
        sellersTemp.map((child) => {
          if (child.partner_id === parseInt(item.id))
            firstLevelChild.children.push({
              key: `${item.id}_${child.id}`,
              label: `${child.name} (${child.id})`,
            });
        });
        sortByField(firstLevelChild.children, "label");
        partnersSellersObj.value.push(firstLevelChild);
      });
    }
  } catch (error) {
    console.error("Error fetching partner sellers:", error);
  } finally {
    store.commit("setLoading", false);
  }
};

const fetchTagGroups = async () => {
  try {
    store.commit("setLoading", true);

    const token = localStorage.getItem("adminToken");
    const response = await axios.get(API_BASE_URL + "/tag-group", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data) {
      tagGroups.value = response.data;
    }
  } catch (error) {
    console.error("Error fetching tag groups:", error);
  } finally {
    store.commit("setLoading", false);
  }
};

const fetchTag = async () => {
  try {
    if (!tagId) return;
    store.commit("setLoading", true);

    const token = localStorage.getItem("adminToken");
    const response = await axios.get(API_BASE_URL + "/tag/" + tagId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.success) {
      let tagData = response.data.tag;

      form.value.name = tagData.name;
      form.value.url = tagData.url;
      form.value.cpm = tagData.cpm;
      form.value.geos_blacklist = tagData.geos_blacklist?.length
        ? tagData.geos_blacklist.toUpperCase().split(",")
        : [];
      form.value.geos_whitelist = tagData.geos_whitelist?.length
        ? tagData.geos_whitelist.toUpperCase().split(",")
        : [];
      form.value.wc_percent = tagData.wc_percent * 100;
      form.value.tag_group = tagData.tag_group;
      form.value.active = tagData.active;
      form.value.priority = tagData.priority;
      form.value.tag_type = tagData.tag_type;
      form.value.domains_bl = tagData.domains_bl?.length
        ? tagData.domains_bl.split(/\s*,\s*/)
        : [];
      form.value.domains_wl = tagData.domains_wl?.length
        ? tagData.domains_wl.split(/\s*,\s*/)
        : [];
      form.value.os_bl = tagData.os_bl?.length
        ? tagData.os_bl.split(/\s*,\s*/)
        : [];
      form.value.os_wl = tagData.os_wl?.length
        ? tagData.os_wl.split(/\s*,\s*/)
        : [];

      form.value.content_pack_id = tagData.content_pack_id;
      form.value.tier = tagData.tier;

      let selBlArr = tagData.sel_bl?.length ? tagData.sel_bl.split(",") : [];
      selBlArr.map((item) => {
        if (item.includes("_")) {
          const parentKey = item.split("_")[0];
          sellersBlacklist.value[parentKey] = {
            partialChecked: true,
            checked: false,
          };
        }
        sellersBlacklist.value[item] = {
          partialChecked: false,
          checked: true,
        };
      });

      let selWlArr = tagData.sel_wl?.length ? tagData.sel_wl.split(",") : [];
      selWlArr.map((item) => {
        if (item.includes("_")) {
          const parentKey = item.split("_")[0];
          sellersWhitelist.value[parentKey] = {
            partialChecked: true,
            checked: false,
          };
        }
        sellersWhitelist.value[item] = {
          partialChecked: false,
          checked: true,
        };
      });
    }
  } catch (error) {
    console.error("Error fetching tag:", error);
  } finally {
    store.commit("setLoading", false);
  }
};

const submitTag = async () => {
  submitted.value = true;
  if (form.value.name && form.value.url && form.value.cpm)
    try {
      form.value.sel_bl = [];
      form.value.sel_wl = [];

      for (const key in sellersBlacklist.value) {
        if (Object.hasOwnProperty.call(sellersBlacklist.value, key)) {
          const item = sellersBlacklist.value[key];
          if (item.checked) {
            form.value.sel_bl.push(key);
          }
        }
      }
      for (const key in sellersWhitelist.value) {
        if (Object.hasOwnProperty.call(sellersWhitelist.value, key)) {
          const item = sellersWhitelist.value[key];
          if (item.checked) {
            form.value.sel_wl.push(key);
          }
        }
      }
      const selectedTagGroup = tagGroups.value.find(
        (item) => item.id === form.value.tag_group
      ) || { name: "" };
      form.value.partner = selectedTagGroup.name;
      form.value.wc_percent /= 100;
      store.commit("setLoading", true);
      const token = localStorage.getItem("adminToken");
      const response = await axios.post(API_BASE_URL + "/add-tag", form.value, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data?.success) {
        // Clear form after successful submission
        form.value = {
          name: "",
          url: "",
          cpm: 0,
          geos_blacklist: [],
          geos_whitelist: [],
          wc_percent: 0,
          tag_group: 0,
          active: activeStatus[0].value,
          partner: "",
          priority: 0,
          sel_bl: [],
          sel_wl: [],
          tag_type: tagTypes[0].value,
          domains_bl: [],
          domains_wl: [],
          os_bl: [],
          os_wl: [],
          tier: "3",
        };

        submitted.value = false;

        toast.add({
          severity: "success",
          summary: "Success",
          detail: `Tag ${tagId ? "updated" : "added"} successfully!`,
          life: 4000,
        });
        router.replace({ name: "TagsList" });
      } else {
        // Handle unsuccessful submission
        toast.add({
          severity: "error",
          summary: "Error",
          detail: `Something went wrong...`,
          life: 4000,
        });
      }
    } catch (error) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: `Something went wrong...`,
        life: 4000,
      });
      console.log(error);
    } finally {
      store.commit("setLoading", false);
    }
};

onMounted(() => {
  fetchCountryList();
  fetchPartners();
  fetchTagGroups();
  fetchTag();
  fetchContentPacks();
});
</script>

<style>
li.p-treenode:has(> .hideparent) {
  display: none;
}
</style>
